/**
 * @module TherapyCourseEditor/PhasePatient
 * @desc This module provides a form for editing a patient's tasks in a phase of a therapy course.
 */
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DocumentReference } from 'firebase/firestore';
import {
  Patient,
  TherapyCoursePhasePatient,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { CircularProgress, Switch, Typography } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import { getTranslatableField } from '../../services/i18n';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';

type PhasePatientProps = {
  patient: Patient;
  patientRef: DocumentReference<Patient>;
  currentPhase: number;
  phase: TherapyCoursePhase;
  setPhases: Dispatch<SetStateAction<TherapyCoursePhase[]>>;
  taskPoolOptions: TaskPoolOption[];
  language: string;
};

export function PhasePatient(props: PhasePatientProps) {
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();

  const { patient, currentPhase, patientRef, phase, setPhases } = props;

  const phasePatients = useMemo(() => phase.patients || [], [phase.patients]);

  const phasePatient = useMemo(
    () =>
      phasePatients.find((p) => p.id === patientRef.id) ||
      ({
        id: patientRef.id,
        patient: patientRef,
        activeTasks: {},
      } as TherapyCoursePhasePatient),
    [phasePatients, patientRef]
  );

  const phaseTasks = phase.phaseTasks || [];

  let patientActiveTasks = useMemo(
    () => phasePatient.activeTasks || ({} as Record<string, boolean>),
    [phasePatient.activeTasks]
  );

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, taskId: string) => {
      const newPatient = {
        ...phasePatient,
        activeTasks: { ...patientActiveTasks, [taskId]: e.target.checked },
      };
      const newPatients = [
        ...phasePatients.filter((p) => p.id !== phasePatient.id),
        newPatient,
      ];
      setPhases((phases) => {
        const newPhases = phases.map((p) => {
          if (p.id === phase.id) {
            return {
              ...p,
              patients: newPatients,
            };
          } else {
            return p;
          }
        });
        console.log('newPhases', newPhases);
        return newPhases;
      });
    },
    [patientActiveTasks, phase.id, phasePatient, phasePatients, setPhases]
  );

  return (
    <Card
      key={`${patient.licenceCode}-phase-${currentPhase}`}
      variant="outlined"
    >
      <CardContent>
        {taskPoolStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <>
            <Typography gutterBottom variant="h6">
              {patient.remark} - {patient.licenceCode}
            </Typography>
            {taskPoolData &&
              phaseTasks.map((task) => {
                const taskId = task.id;
                const taskPoolTask = taskPoolData.find(
                  (tpt) => tpt.id === taskId
                );
                return taskPoolTask ? (
                  <FormGroup key={taskId} row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            taskId in patientActiveTasks
                              ? patientActiveTasks[taskId]
                              : true
                          }
                          onChange={(e) => handleCheckboxChange(e, taskId)}
                        />
                      }
                      label={getTranslatableField(taskPoolTask?.title)}
                    />
                  </FormGroup>
                ) : null;
              })}
          </>
        )}
      </CardContent>
    </Card>
  );
}
