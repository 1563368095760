import { useMemo } from 'react';
import {
  TaskPoolTask,
  TaskPoolTaskCategory,
  TherapyPlan,
} from '../firebase/firebaseModels';
import { useTranslation } from 'react-i18next';

export function useCreatedModified(
  data: TaskPoolTask | TaskPoolTaskCategory | TherapyPlan | null
): {
  createdBy: any;
  created: any;
  modifiedBy: any;
  modified: any;
} {
  const { t } = useTranslation();

  return useMemo(() => {
    const createdBy = data?.createdBy || t('Administrator');
    const created = data?.created?.toDate() || t('Unknown Date');
    const modifiedBy = data?.modifiedBy;
    const modified = data?.modified?.toDate();
    return { createdBy, created, modifiedBy, modified };
  }, [data?.created, data?.createdBy, data?.modified, data?.modifiedBy, t]);
}
