import { Timestamp } from 'firebase/firestore';

export type TimestampProperty = Date | Timestamp | string | null;

export type StatisticsRowDataType = {
  id: string;
  label: string;
  type: 'time' | 'duration' | 'percentage';
  min: number | Date | null;
  max: number | Date | null;
  average?: number | Date | null;
  nValues?: number;
  statisticsGroup: 'sleep_diary' | 'sleep_diary_evening';
};

export type StatisticTableLabelsAndKeysType = {
  label: string;
  key:
    | 'laydowntime'
    | 'bedtime'
    | 'gettinguptime'
    | 'time_in_bed'
    | 'grand_time_in_bed'
    | 'time_asleep'
    | 'nonsleep'
    | 'sleep_efficiency'
    | 'total_naptime_asleep'
    | 'total_naptime_in_bed'
    | 'sleep_onset_duration'
    | 'grand_night_sleep_efficiency';
  type: 'time' | 'duration' | 'percentage';
  dismissZero?: boolean;
  statisticsGroup: 'sleep_diary' | 'sleep_diary_evening';
};

export const statisticTableLabelsAndKeys: StatisticTableLabelsAndKeysType[] = [
  {
    label: 'Laid Down',
    key: 'laydowntime',
    type: 'time',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Turned Off Light',
    key: 'bedtime',
    type: 'time',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Got Out of Bed Time',
    key: 'gettinguptime',
    type: 'time',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Time in Bed (inc. lights on)',
    key: 'grand_time_in_bed',
    type: 'duration',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Time in Bed (lights off)',
    key: 'time_in_bed',
    type: 'duration',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Sleep Onset Latency',
    key: 'sleep_onset_duration',
    type: 'duration',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Wake After Sleep Onset',
    key: 'nonsleep',
    type: 'duration',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Total Sleep Time',
    key: 'time_asleep',
    type: 'duration',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Sleep Efficiency (inc. lights on)',
    key: 'grand_night_sleep_efficiency',
    type: 'percentage',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Sleep Efficiency (lights off)',
    key: 'sleep_efficiency',
    type: 'percentage',
    statisticsGroup: 'sleep_diary',
  },
  {
    label: 'Daytime Rest',
    key: 'total_naptime_in_bed',
    type: 'duration',
    dismissZero: true,
    statisticsGroup: 'sleep_diary_evening',
  },
  {
    label: 'Daytime Nap',
    key: 'total_naptime_asleep',
    type: 'duration',
    dismissZero: true,
    statisticsGroup: 'sleep_diary_evening',
  },
];

export interface Marking {
  date: string;
  type: string;
  completed: boolean;
  completedTime?: TimestampProperty;
}

export interface MorningEntry extends Marking {
  layDownTime?: TimestampProperty;
  bedtime: TimestampProperty;
  sleeptime: TimestampProperty;
  wakeuptime: TimestampProperty;
  gettinguptime: TimestampProperty;
  sleepOnsetDuration?: number | null;
  wakeups: number;
  nonsleep: number;
  sleepQuality: number | null;
  tookMedication: boolean;
  sleepDisturbances: string[];
  remarks: string;
}

export type Nap = {
  bedtime: TimestampProperty;
  gettinguptime: TimestampProperty;
  didSleep: boolean;
  sleepDuration: number | null;
  sleeptime: TimestampProperty;
  wakeuptime: TimestampProperty;
};

export interface EveningEntry extends Marking {
  naps: Nap[];
  remarks: string;
}
export interface SleepEfficiencyData {
  marking_date: string;
  type: 'morning' | 'evening' | 'homework';
  raw_data: MorningEntry | EveningEntry | Marking;
}

export interface SleepEfficiencyMorningData extends SleepEfficiencyData {
  bedtime: TimestampProperty;
  sleeptime: TimestampProperty;
  wakeuptime: TimestampProperty;
  gettinguptime: TimestampProperty;
  night_sleep_efficiency: number | null;
  sleep_quality: number | null;
  time_asleep: number | null;
  time_in_bed: number | null;
  sleep_onset_duration: number | null;
  raw_data: MorningEntry;
}

export interface SleepEfficiencyEveningData extends SleepEfficiencyData {
  nap_sleep_efficiency: number | null;
  num_naps: number | null;
  total_naptime_asleep: number | null;
  total_naptime_in_bed: number | null;
  raw_data: EveningEntry;
}

export type StatisticDay = {
  marking_date: string;
  sleep_efficiency_data:
    | SleepEfficiencyMorningData[]
    | SleepEfficiencyEveningData[]
    | null;
  completed_tasks: number | null;
  completed_tasks_moving_average: number | null;
  percantage_completed_tasks: number | null;
  percantage_completed_tasks_moving_average: number | null;
  nap_sleep_efficiency: number | null;
  nap_sleep_efficiency_moving_average: number | null;
  night_sleep_efficiency: number | null;
  night_sleep_efficiency_moving_average: number | null;
  sleep_efficiency: number | null;
  sleep_efficiency_moving_average: number | null;
  sleep_quality: number | null;
  sleep_quality_moving_average: number | null;
  sleep_onset_duration: number | null;
  sleep_onset_duration_moving_average: number | null;
  laydowntime: Date | null;
  bedtime: Date | null;
  gettinguptime: Date | null;
  sleeptime: Date | null;
  wakeuptime: Date | null;
  wakeups: number;
  time_in_bed: number | null;
  time_asleep: number | null;
  night_nonsleep: number | null;
  nonsleep: number | null;
  timeasleeptotal: number | null;
  total_naptime_asleep: number | null;
  total_naptime_in_bed: number | null;
  grand_night_sleep_efficiency: number | null;
  grand_night_sleep_efficiency_moving_average: number | null;
  grand_time_in_bed: number | null;
};
