import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { useFirestore } from 'reactfire';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { Patient, User } from '../firebase/firebaseModels';
import useUserProvider from '../firebase/useUserProvider';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { FirebaseError } from 'firebase/app';
import usePortalUser from '../firebase/usePortalUser';

export type TPatientData = {
  status: 'loading' | 'success' | 'error';
  data: Patient[] | null;
  appUsersStatus: 'loading' | 'success' | 'error';
  appUsers: User[] | null;
  error: FirebaseError | null;
  appUsersError: FirebaseError | null;
  isPatientValid: (patient: Patient | null) => boolean;
  isPatientValidById: (patientId: string | null) => boolean;
};

export const PatientContext = createContext<TPatientData | null>(null);

export const useAppUser = (patient: Patient | null) => {
  const context = useContext(PatientContext);

  if (!context) {
    throw new Error('useAppUser must be used within a PatientProvider');
  }

  const { appUsers } = context;

  if (!patient) return null;
  const licenceCode = patient.licenceCode;
  const userId = patient.user?.id;
  if (!licenceCode || !userId) return null;

  return (
    appUsers?.find(
      (user) => user.licenceCode === licenceCode && user.id === userId
    ) || null
  );
};

export const usePatient = (patientId: string | null) => {
  const context = useContext(PatientContext);

  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider');
  }

  const { data: patientData } = context;

  return patientData?.find((patient) => patient.id === patientId) || null;
};

export const PatientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { isSuperAdmin } = usePortalUser();
  const {
    data: userProviderData,
    status: userProviderStatus,
    providerRef,
  } = useUserProvider();

  const [nTimeouts, setNTimeouts] = useState(0);
  const [nTimeoutsAppUsers, setNTimeoutsAppUsers] = useState(0);
  const [appUsers, setAppUsers] = useState<User[] | null>(null);
  const [patientData, setPatientData] = useState<Patient[] | null>(null);
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );
  const [appUsersStatus, setAppUsersStatus] = useState<
    'loading' | 'success' | 'error'
  >('loading');
  const [error, setError] = useState<FirebaseError | null>(null);
  const [appUsersError, setAppUsersError] = useState<FirebaseError | null>(
    null
  );
  // get app users once the patient data is loaded
  useEffect(() => {
    if (!patientData) return;
    // console.log('PatientProvider');
    // console.log('patientData', patientData);
    // console.log('isSuperAdmin', isSuperAdmin);
    // console.log('userProviderData', userProviderData);
    // console.log('providerRef', providerRef);
    setAppUsersStatus('loading');
    setAppUsersError(null);
    if (isSuperAdmin) {
      const licenceCodes = patientData.map((patient) => patient.licenceCode);

      const usersRef = collection(firestore, 'Users');
      const usersQuery = query(
        usersRef,
        where('licenceCode', 'in', licenceCodes)
      );

      const unsubscribe = onSnapshot(
        usersQuery,
        (snapshot) => {
          const users = snapshot.docs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as User)
          );
          setAppUsers(users);
          setNTimeoutsAppUsers(0);
          setAppUsersStatus('success');
        },
        (error) => {
          setAppUsersStatus('error');
          setError(error as FirebaseError);
          setAppUsers(null);
          setAppUsersError(error as FirebaseError);

          console.error('Error getting App Users collection', error);
          // Incremental backoff for retrying
          const retryDelay = 1000 * (nTimeoutsAppUsers + 1);
          setTimeout(() => {
            console.log(
              `PatientProvider retrying app users after delay ${retryDelay}`
            );
            setNTimeoutsAppUsers((prevTimeouts) => prevTimeouts + 1);
          }, retryDelay);
        }
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } else {
      // Fetch app users individually by userId using onSnapshot
      const unsubscribes: (() => void)[] = [];

      patientData.forEach((patient) => {
        // console.log('fetching patient user', patient.user);
        const userId = patient.user?.id;
        if (userId) {
          const userRef = doc(firestore, 'Users', userId);
          const unsubscribe = onSnapshot(
            userRef,
            (userDoc) => {
              setAppUsers((prevUsers) => {
                const userData = { ...userDoc.data(), id: userDoc.id } as User;
                if (prevUsers) {
                  return [
                    ...prevUsers.filter((u) => u.id !== userData.id),
                    userData,
                  ];
                }
                return [userData];
              });
              setAppUsersStatus('success');
            },
            (error) => {
              console.error('Error fetching user:', error);
              setAppUsersError(error as FirebaseError);
              setAppUsersStatus('error');
            }
          );
          unsubscribes.push(unsubscribe);
        }
      });

      // Cleanup function to unsubscribe from all listeners
      return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    }
  }, [
    patientData,
    firestore,
    nTimeoutsAppUsers,
    isSuperAdmin,
    userProviderData?.id,
    providerRef,
    userProviderData,
  ]);

  useEffect(() => {
    if (!userProviderData || userProviderStatus !== 'success' || !email) {
      return;
    }
    setStatus('loading');
    setError(null);
    setPatientData(null);
    const PatientRef = collection(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'Patient'
    );

    const unsubscribe = onSnapshot(
      PatientRef,
      (snapshot) => {
        const PatientData: Patient[] = snapshot.docs
          .map((doc) => {
            // fetch app user connected to the patient
            const docData = doc.data() as Patient;
            if (!docData.user || docData.status === 'deleted') {
              return null;
            }
            // only add if user's licenceCode matches the patient's licenceCode
            return {
              ...doc.data(),
              id: doc.id,
            } as Patient;
          })
          .filter((doc) => doc !== null) as Patient[];
        setPatientData(PatientData);
        setStatus('success');
        setError(null);
        setNTimeouts(0);
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setPatientData(null);

        console.error('Error getting Patient collection', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('PatientProvider retrying patient data after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [email, firestore, nTimeouts, userProviderData, userProviderStatus]);

  const data = useMemo(() => {
    // filter out patients whos licenceCode does not match the user's licenceCode
    if (!patientData || !appUsers) return null;
    return patientData.filter((patient) => {
      const licenceCode = patient.licenceCode;
      const userId = patient.user?.id;
      if (!licenceCode || !userId) return false;
      const user = appUsers.find(
        (user) => user.licenceCode === licenceCode && user.id === userId
      );
      return !!user;
    });
  }, [appUsers, patientData]);

  const isPatientValid = useCallback(
    (patient: Patient | null) => {
      if (!patient) return false;
      const licenceCode = patient.licenceCode;
      const userId = patient.user?.id;
      if (!licenceCode || !userId) return false;
      return !!appUsers?.some(
        (user) => user.licenceCode === licenceCode && user.id === userId
      );
    },
    [appUsers]
  );

  const isPatientValidById = useCallback(
    (patientId: string | null) => {
      if (!patientId) return false;
      const patient =
        patientData?.find((patient) => patient.id === patientId) || null;
      return isPatientValid(patient);
    },
    [patientData, isPatientValid]
  );

  return (
    <PatientContext.Provider
      value={{
        status,
        data,
        error,
        appUsers,
        appUsersStatus,
        appUsersError,
        isPatientValid,
        isPatientValidById,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
