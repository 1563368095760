import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { useMemo } from 'react';
import { StatisticDay } from './types';
import { DATE_LABEL_WIDTH, DayView } from '../DayStatistics/DayView';

type DayGraphTabProps = {
  statisticsSlice: StatisticDay[] | null;
  isUpdating: boolean;
};

export default function DayGraphTab(props: DayGraphTabProps) {
  const { t } = useTranslation();
  const { statisticsSlice } = props;

  const dayWidth = 100; // Adjust as needed
  const hourWidth = dayWidth / 24;
  const dayHeight = 30; // Adjust as needed
  const fullWidth = dayWidth;

  // first date is fromDateString or first date in statisticsSlice
  const firstDateString = useMemo(() => {
    return statisticsSlice?.[0]?.marking_date;
  }, [statisticsSlice]);

  const lastDateString = useMemo(() => {
    return statisticsSlice?.[statisticsSlice.length - 1]?.marking_date;
  }, [statisticsSlice]);

  // calculate total number of days between fromDateString and toDateString
  const totalNumberOfDays = useMemo(() => {
    const firstDate = new Date(firstDateString || '');
    const lastDate = new Date(lastDateString || '');
    return Math.ceil(
      (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)
    );
  }, [firstDateString, lastDateString]);

  const range = useMemo(() => {
    return Array.from(
      { length: totalNumberOfDays },
      (_, idx) => totalNumberOfDays - 1 - idx
    );
  }, [totalNumberOfDays]);

  const dayRows = useMemo(
    () =>
      statisticsSlice
        ? range
            .map((daysSince, idx) => {
              const statisticsDate = new Date(lastDateString || '');
              statisticsDate.setDate(statisticsDate.getDate() - daysSince);
              const dateLabel = statisticsDate.toLocaleDateString();

              // Skip invalid dates
              if (isNaN(statisticsDate.getTime())) {
                return null;
              }

              return (
                <Box
                  sx={{
                    width: `${dayWidth}%`,
                    height: dayHeight,
                    position: 'relative',
                    overflow: 'hidden',
                    mb: '2px',
                  }}
                >
                  <DayView
                    key={`dv-${idx}`}
                    statisticDate={statisticsDate}
                    dateLabel={dateLabel}
                    statistics={statisticsSlice}
                    fullWidth={fullWidth}
                    dayWidth={dayWidth}
                    hourWidth={hourWidth}
                    dayHeight={dayHeight}
                  />
                </Box>
              );
            })
            .filter((dr) => dr != null)
        : [],
    [fullWidth, hourWidth, range, statisticsSlice, lastDateString]
  );
  return (
    <Box>
      {dayRows.length > 0 ? dayRows : <></>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: `${dayWidth}%`,
          ml: `${DATE_LABEL_WIDTH}px`,
          pr: `${DATE_LABEL_WIDTH}px`,
        }}
      >
        {['00', '04', '08', '12', '16', '20', '24'].map((label) => (
          <Typography key={label} variant="caption">
            {label}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
