import { useFirestore, useFunctions } from 'reactfire';
import { Licence, Patient, User } from '../firebase/firebaseModels';
import { httpsCallable } from '@firebase/functions';
import {
  doc,
  getDoc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { useCallback, useState } from 'react';
import useUserProvider from './useUserProvider';

type AdminFindLicenceParams = {
  licenceCode: string;
};
type AdminFindLicenceResult = {
  success: boolean;
  message?: string;
  licenceId?: string;
};

export function useAddPatient(email: string | null) {
  const firestore = useFirestore();
  const [addPatientError, setAddPatientError] = useState<Error | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { data: userProviderData } = useUserProvider();
  const functions = useFunctions();
  const adminFindLicence = httpsCallable<
    AdminFindLicenceParams,
    AdminFindLicenceResult
  >(functions, 'adminfindlicence');

  const addPatient = useCallback(
    (
      licenceCode: string,
      remark: string,
      onSuccess?: (newPatient: DocumentReference<Patient>) => void
    ) => {
      if (!userProviderData?.id || !licenceCode || !email) {
        return;
      }
      setIsUpdating(true);
      // console.log('Looking for licence with code', licenceCode);
      // look for Licence from firebase /Licences, get user id, add to TherapyCourse
      setAddPatientError(null);
      adminFindLicence({ licenceCode })
        .then((result) => {
          const { data } = result;
          // console.log('data', data);
          const { success, message, licenceId } = data;
          if (!success) {
            setAddPatientError(new Error(message));
            setIsUpdating(false);
            return;
          }
          if (!licenceId) {
            setAddPatientError(new Error('Licence ID not valid'));
            setIsUpdating(false);
            return;
          }
          const licenceRef = doc(
            firestore,
            'Licences',
            licenceId
          ) as DocumentReference<Licence>;
          let licenceData: Licence | null = null;
          getDoc(licenceRef)
            .then((licenceDoc) => {
              if (!licenceDoc.exists()) {
                setAddPatientError(new Error('Licence not found'));
                setIsUpdating(false);
                return;
              }
              licenceData = licenceDoc.data();
              console.log('licenceData', licenceData);
              if (!licenceData) {
                setAddPatientError(new Error('Error getting license'));
                setIsUpdating(false);
                return;
              }
              const userId = licenceData.user;
              if (!userId) {
                setAddPatientError(new Error('UserId not valid'));
                setIsUpdating(false);
                return;
              }
              const userRef = doc(
                firestore,
                'Users',
                userId
              ) as DocumentReference<User>;
              getDoc(userRef)
                .then((userDoc) => {
                  console.log('userDoc', userDoc);
                  const userData = userDoc.data();
                  if (userData) {
                    // does the user's licenceCode match ?
                    if (userData.licenceCode !== licenceCode) {
                      setAddPatientError(
                        new Error(
                          "User's current license code doesn't match license"
                        )
                      );
                      setIsUpdating(false);
                      return;
                    }
                    const patient: Patient = {
                      licenceCode,
                      remark,
                      user: userRef as DocumentReference<User>,
                      modifiedBy: email,
                      createdBy: email,
                      modified: Timestamp.now(),
                      created: Timestamp.now(),
                      approved: false,
                    };
                    const patientRef = doc(
                      firestore,
                      'ServiceProvider',
                      userProviderData.id,
                      'UserData',
                      email,
                      'Patient',
                      licenceCode
                    ) as DocumentReference<Patient>;
                    setDoc(patientRef, patient)
                      .then(() => {
                        console.log('patient added');
                        setAddPatientError(null);
                        onSuccess && onSuccess(patientRef);
                        setIsUpdating(false);
                      })
                      .catch(() => {
                        setAddPatientError(new Error('Error adding patient'));
                        setIsUpdating(false);
                      });
                  }
                })
                .catch((error) => {
                  console.error('Error getting user', error);
                  setAddPatientError(new Error('Error getting user'));
                  setIsUpdating(false);
                });
            })
            .catch((error) => {
              console.error('Error getting license', error);
              setAddPatientError(new Error('Error getting license'));
              setIsUpdating(false);
            });
        })
        .catch((error) => {
          console.error('Error finding licence', error);
          setAddPatientError(new Error('Licence not found'));
          setIsUpdating(false);
        });
    },
    [userProviderData?.id, email, adminFindLicence, firestore]
  );

  return { addPatient, addPatientError, isUpdating };
}
